<template>
  <div class="reports">
    <div
      v-for="a15 of a15List"
      :key="a15.a15Guid"
      class="report-wrapper">
      <div class="report">
        <div class="header">
          哈尔滨至肇源高速公路工程建设项目
        </div>
        <div class="sub-header">
          <div>承包单位：</div>
          <div>黑龙江省龙建路桥第四工程有限公司</div>
          <div>合同号：</div>
          <div>A2</div>
          <div>监理单位：</div>
          <div>黑龙江省公路工程监理咨询有限公司</div>
          <div>编<span class="text-transparent">一</span>号：</div>
          <div>{{ a15.manualSerialNo }}</div>
        </div>
        <div class="table-title">
          <div class="main-titel">
            中间计量表
          </div>
          <div class="sub-title">
            A—15
          </div>
        </div>
        <table>
          <tbody>
            <tr>
              <td>支付项目编号</td>
              <td>{{ a15.meterageDisplayCode }}</td>
              <td>项目名称</td>
              <td>{{ a15.meterageName }}</td>
            </tr>
            <tr>
              <td>起止桩号</td>
              <td>{{ a15.startMileage }}~{{ a15.endMileage }}</td>
              <td>部位</td>
              <td>{{ a15.part }}</td>
            </tr>
            <tr>
              <td>图号</td>
              <td>{{ a15.pictureNo }}</td>
              <td>中间交工证书号</td>
              <td>{{ a15.certNo }}</td>
            </tr>
            <tr class="image-row">
              <td colspan="4">
                <div class="text">计量草图及几何尺寸：</div>
                <img v-if="a15.pictureGuid" :src="`http://47.93.222.187:5008/${a15.pictureGuid}.jpg`" alt="">
              </td>
            </tr>
            <tr class="formula-row">
              <td colspan="4">
                <div class="text">计算公式：</div>
                <div>{{ a15.formula }}</div>
              </td>
            </tr>
            <tr>
              <td colspan="4">
                <div class="sign">
                  <div>计量单位</div>
                  <div>{{ a15.unit }}</div>
                  <div>工程数量</div>
                  <div>{{ a15.num.toFixed(3) }}</div>
                  <div>日期</div>
                  <div>2020-06-25</div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="footer">
          <div>承包人：</div>
          <div>专业监理工程师：</div>
        </div>
      </div>
      <div class="break"></div>
    </div>
  </div>
</template>

<script>
import a15Service from '@/services/a15Service'
import PageInfo from '@/support/PageInfo'

export default {
  name: 'Report',
  data () {
    return {
      a15List: []
    }
  },
  methods: {
    getData () {
      a15Service.list(new PageInfo({ pageSize: 3000, pageIndex: 1, fieldOrder: 'MeterageLists.MeterageCode,A15s.StartMileage,A15s.EndMileage,A15s.Part asc' }), { examinationGuid: 'C2D85A4E-D1B0-432B-92B0-4491C9174098' })
        .then(res => {
          if (res.data.code === 1) {
            this.a15List = res.data.data
            console.log('this.a15List', this.a15List)
          }
        })
    }
  },
  created () {
    this.getData()
  }
}
</script>

<style scoped lang="scss">
.reports {
  width: 100%;
  background-color: #515659;
  box-sizing: border-box;
  padding: 2mm 4mm;
  margin: 0 auto;
  text-align: center;
  .report-wrapper {
    width: 210mm;
    height: 297mm;
    margin: 5mm auto;
    padding: 2mm 2mm;
    background-color: white;
    .header {
      font-family: 'SimHei';
      font-size: 10mm;
      font-weight: bold;
      text-align: center;
    }
    .sub-header {
      width: 180mm;
      margin: 0 auto;
      font-family: 'SimSun';
      font-size: 5mm;
      display: grid;
      grid-template-columns: 25mm 1fr 22mm 30mm;
      div {
        text-align: right;
        height: 7mm;
      }
      div:nth-child(even) {
        text-align: left;
        border-bottom: 1px solid #000;
      }
    }
    .table-title {
      font-family: 'SimHei';
      font-weight: bold;
    }
    table {
      width: 100%;
      border: 1px solid #000;
      border-collapse: collapse;
      td {
        font-family: 'SimSun';
        width: 25%;
        height: 20mm;
        border: 1px solid #000;
        .text {
          text-align: left;
        }
        .sign {
          width: 100%;
          display: grid;
          grid-template-columns: repeat(6, 1fr);
          div {
            border-right: 1px solid #000;
          }
          div:last-child {
            border-right: 0;
          }
        }
      }
    }
    .footer {
      display: grid;
      font-family: 'SimSun';
      grid-template-columns: 1fr 1fr;
    }
    .break {
      page-break-after: always;
    }
  }
}
</style>
