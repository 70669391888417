import utility from '@/common/utility'
import auth from '@/common/auth'

export default class ReportModel {
  constructor (props) {
    if (props) {
      this.resourceId = props.reportGuid
      this.reportGuid = props.reportGuid
      this.reportName = props.reportName
      this.reportAlias = props.reportAlias
      this.iconClass = props.iconClass
      this.reportTemplateGuid = props.reportTemplateGuid
      this.staffName = props.staffName
      this.reportType = props.reportType
      this.isPublic = props.isPublic
      this.reportGroup = props.reportGroup
      this.planStatType = props.planStatType
      this.sortId = props.sortId
      this.createUserGuid = props.createUserGuid
      this.createTime = props.createTime
    }
    this.init()
  }

  init () {
    if (!this.resourceId) this.resourceId = ''
    if (!this.reportGuid) this.reportGuid = ''
    if (!this.reportName) this.reportName = ''
    if (!this.reportAlias) this.reportAlias = ''
    if (!this.iconClass) this.iconClass = ''
    if (!this.reportTemplateGuid) this.reportTemplateGuid = ''
    if (!this.staffName) this.staffName = ''
    if (!this.reportType) this.reportType = ''
    if (!this.isPublic) this.isPublic = 0
    if (!this.reportGroup) this.reportGroup = ''
    if (!this.planStatType) this.planStatType = ''
    if (!this.sortId) this.sortId = 0
    if (!this.createUserGuid) this.createUserGuid = auth.getUserInfo().userGuid
    if (!this.createTime) this.createTime = utility.getCurrentTime()
  }

  generatePrimaryKey () {
    this.reportGuid = utility.getUuid()
    this.resourceId = this.reportGuid
  }
}
