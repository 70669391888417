<template>
  <div class="report-index">
    <my-search-bar>
      <el-form :inline="true">
        <el-form-item>
          <el-select
            v-model="searchModel.reportTemplateGuid"
            placeholder="报表模板"
            clearable>
            <el-option
              v-for="reportTemplate in reportTemplates"
              :key="reportTemplate.reportTemplateGuid"
              :label="reportTemplate.reportTemplateName"
              :value="reportTemplate.reportTemplateGuid">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-input
            type="text"
            v-model="searchModel.reportName"
            placeholder="报表名称"
            clearable>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleSearchClick">查询</el-button>
          <el-button type="primary" @click="handleAddClick">添加</el-button>
        </el-form-item>
      </el-form>
    </my-search-bar>

    <my-el-table
      :data="reportList"
      :total="total"
      :page-index="pageIndex"
      :page-size="pageSize"
      @size-change="handlePageSizeChange"
      @current-change="handlePageIndexChange"
      >
      <el-table-column prop="index" width="50" header-align="center" align="center" label="序号"></el-table-column>
      <el-table-column prop="reportName" header-align="center" align="center" label="名称"></el-table-column>
      <el-table-column prop="reportAlias" width="120" header-align="center" align="center" label="别名"></el-table-column>
      <el-table-column prop="iconClass" width="120" header-align="center" align="center" label="图标"></el-table-column>
      <el-table-column prop="reportTemplateName" width="250" header-align="center" align="center" label="报表模板名称"></el-table-column>
      <el-table-column prop="planStatType" width="90" header-align="center" align="center" label="计划统计类型"></el-table-column>
      <el-table-column prop="staffName" width="80" header-align="center" align="center" label="创建者"></el-table-column>
      <el-table-column prop="sortId" width="50" header-align="center" align="center" label="排序"></el-table-column>
      <el-table-column prop="createTime" :formatter="timeFormatter" width="160" header-align="center" align="center" label="创建时间"></el-table-column>
      <el-table-column  header-align="center" align="center" width="90" label="操作">
        <template v-if="!scope.row.empty" slot-scope="scope">
          <el-tooltip class="item" effect="light" content="编辑" :open-delay="500" placement="top">
            <i @click="handleEditClick(scope.row)" class="text-info far fa-edit mr-2 operation-button"></i>
          </el-tooltip>
          <el-tooltip class="item" effect="light" content="删除" :open-delay="500" placement="top">
            <i @click="handleDeleteClick(scope.row)" class="text-danger far fa-trash-alt operation-button"></i>
          </el-tooltip>
        </template>
      </el-table-column>
    </my-el-table>

    <my-dialog ref="formDialog"
      :title="formDialogTitle"
      size="md"
      ok-text="保存"
      cancel-text="取消"
      @ok-click="handleOkClick">
      <report-form
        ref="reportForm"
        :reportModel="reportModel">
      </report-form>
    </my-dialog>
  </div>
</template>

<script>
import MySearchBar from '@/components/MySearchBar/MySearchBar'
import MyDialog from '@/components/MyDialog/MyDialog'
import MyElTable from '@/components/MyElTable/MyElTable'
import ReportForm from '@/views/ReportManage/ReportForm'
import ReportModel from '@/model/ReportModel'
import reportService from '@/services/reportService'
import reportTemplateService from '@/services/reportTemplateService'
import PageOperation from '@/mixins/PageOperation'
import PageInfo from '@/support/PageInfo'

export default {
  name: 'ReportIndex',
  mixins: [
    PageOperation
  ],
  components: {
    MyDialog,
    MySearchBar,
    MyElTable,
    ReportForm
  },
  data () {
    return {
      total: 0,
      reportList: [],
      reportModel: new ReportModel(),
      reportTemplates: [],
      formDialogTitle: '',
      searchModel: {
        reportName: '',
        reportAlias: '',
        ownerCode: '',
        companyGuid: ''
      }
    }
  },
  methods: {
    handleSearchClick () {
      this._getReportList()
    },
    handleAddClick () {
      this.formDialogTitle = '添加报表信息'
      this.reportModel = new ReportModel()
      this.$refs.formDialog.open()
    },
    handleEditClick (row) {
      this.formDialogTitle = '编辑报表信息'
      this.reportModel = new ReportModel(row)
      this.$refs.formDialog.open()
    },
    handleDeleteClick (row) {
      this.$confirm('您确定要删除吗？')
        .then(() => {
          let _reportModel = new ReportModel(row)
          reportService.delete(_reportModel.resourceId)
            .then(res => {
              window.console.log(res)
              if (res.data.code === 1) {
                this.$message({
                  type: 'success',
                  message: '删除成功！'
                })
                this._getReportList()
              } else {
                this.$message({
                  type: 'error',
                  message: res.data.msg
                })
              }
            })
            .catch(err => {
              window.console.log(err)
              this.$message({
                type: 'error',
                message: '删除失败！'
              })
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '您已取消删除！'
          })
        })
    },
    handleOkClick () {
      this.$refs.reportForm.validate()(valid => {
        if (valid) {
          if (!this.reportModel.resourceId) {
            this._save()
          } else {
            this._edit()
          }
        } else {
          this.$message({
            type: 'error',
            message: '请检查您的输入！'
          })
        }
      })
    },
    _getReportList () {
      reportService.list(new PageInfo({ pageSize: this.pageSize, pageIndex: this.pageIndex, fieldOrder: 'reports.sortId asc' }), this.searchModel)
        .then(res => {
          if (res.data.code === 1) {
            this.total = res.data.pageCount
            this.reportList = res.data.data
            this.$message({
              type: 'success',
              message: '列表加载成功！'
            })
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          window.console.log(err)
          this.$message({
            type: 'error',
            message: '列表加载失败！'
          })
        })
    },
    _save () {
      reportService.add(this.reportModel)
        .then(res => {
          window.console.log(res)
          if (res.data.code === 1) {
            this.$refs.formDialog.close()
            this._getReportList()
            this.$message({
              type: 'success',
              message: '添加成功！'
            })
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          this.$message({
            type: 'error',
            message: '添加失败！'
          })
          window.console.log(err.request.response)
        })
    },
    _edit () {
      reportService.edit(this.reportModel)
        .then(res => {
          window.console.log(res)
          if (res.data.code === 1) {
            this.$refs.formDialog.close()
            this._getReportList()
            this.$message({
              type: 'success',
              message: '修改成功！'
            })
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          this.$message({
            type: 'error',
            message: '修改失败！'
          })
          window.console.log(err.request.response)
        })
    },
    _getReportTemplates () {
      reportTemplateService.list()
        .then(res => {
          if (res.data.code === 1) {
            this.reportTemplates = res.data.data
          } else {
            this.$message({
              type: 'error',
              message: '获取数据失败！'
            })
          }
        })
        .catch(err => {
          this.$message({
            type: 'error',
            message: '获取数据失败！'
          })
          console.log(err.request.response)
        })
    }
  },
  created () {
    this._getReportList()
    this.refreshMethod = this._getReportList
    this._getReportTemplates()
  }
}
</script>
