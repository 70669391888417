<template>
  <el-form
    ref="elForm"
    :rules="rules"
    :model="reportModel"
    label-width="110px"
    >
    <el-form-item label="名称：" prop="reportName">
      <el-input
        type="text"
        v-model="reportModel.reportName">
      </el-input>
    </el-form-item>
    <el-form-item label="别名：" prop="reportAlias">
      <el-input
        type="text"
        v-model="reportModel.reportAlias">
      </el-input>
    </el-form-item>
    <el-form-item label="图标：" prop="iconClass">
      <el-input
        type="text"
        v-model="reportModel.iconClass">
      </el-input>
    </el-form-item>
    <el-form-item label="报表类型：" prop="reportType">
      <el-select v-model="reportModel.reportType" clearable>
        <el-option
          v-for="reportType in reportTypes"
          :key="reportType.dictionaryGuid"
          :label="reportType.dictionaryValue"
          :value="reportType.dictionaryValue"
          >
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="计划统计类型：" prop="planStatType">
      <el-select v-model="reportModel.planStatType" clearable>
        <el-option
          v-for="planStatType in planStatTypes"
          :key="planStatType.dictionaryGuid"
          :label="planStatType.dictionaryValue"
          :value="planStatType.dictionaryValue"
          >
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="报表模板：" prop="reportTemplateGuid">
      <el-select v-model="reportModel.reportTemplateGuid" clearable>
        <el-option
          v-for="reportTemplate in reportTemplates"
          :key="reportTemplate.reportTemplateGuid"
          :label="reportTemplate.reportTemplateName"
          :value="reportTemplate.reportTemplateGuid"
          >
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="报表分组：" prop="reportGroup">
      <el-select v-model="reportModel.reportGroup" clearable>
        <el-option
          v-for="reportGroup in reportGroups"
          :key="reportGroup.dictionaryGuid"
          :label="reportGroup.dictionaryValue"
          :value="reportGroup.dictionaryValue"
          >
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="是否公开：">
      <el-switch
        v-model.number="reportModel.isPublic"
        active-color="#13ce66"
        inactive-color="#ff4949"
        :active-value="1"
        :inactive-value="0"
        active-text="是"
        inactive-text="否">
      </el-switch>
    </el-form-item>
    <el-form-item label="排序：" prop="sortId">
      <el-input-number
        v-model="reportModel.sortId"
        :step="1"
        :min="0"
        :precision="0"
        step-strictly>
      </el-input-number>
    </el-form-item>
  </el-form>
</template>

<script>
import ReportModel from '@/model/ReportModel'
import reportTemplateService from '@/services/reportTemplateService'
import dictionaryService from '@/services/dictionaryService'
import config from '@/config'
import PageInfo from '@/support/PageInfo'

export default {
  name: 'ReportForm',
  props: {
    reportModel: {
      type: ReportModel,
      default () {
        return new ReportModel()
      }
    }
  },
  data () {
    return {
      reportTemplates: [],
      reportTypes: [],
      planStatTypes: [],
      reportGroups: [],
      rules: {
        reportName: [
          {
            required: true,
            message: '请输入名称',
            trigger: 'blur'
          }
        ],
        reportAlias: [
          {
            required: true,
            message: '请输入别名',
            trigger: 'blur'
          }
        ],
        reportTemplateGuid: [
          {
            required: true,
            message: '请选择报表模板',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  methods: {
    validate () {
      return this.$refs.elForm.validate
    },
    _getReportTemplates () {
      reportTemplateService.list()
        .then(res => {
          if (res.data.code === 1) {
            this.reportTemplates = res.data.data
          } else {
            this.$message({
              type: 'error',
              message: '获取数据失败！'
            })
          }
        })
        .catch(err => {
          this.$message({
            type: 'error',
            message: '获取数据失败！'
          })
          console.log(err.request.response)
        })
    },
    _getReportGroups () {
      dictionaryService.list(new PageInfo({ pageSize: config.bigPageSize, pageIndex: 1, fieldOrder: 'sortId' }), { dictionaryKey: 'reportGroup' })
        .then(res => {
          if (res.data.code === 1) {
            this.reportGroups = res.data.data
          } else {
            this.$message({
              type: 'error',
              message: '获取数据失败！'
            })
          }
        })
        .catch(err => {
          this.$message({
            type: 'error',
            message: '获取数据失败！'
          })
          console.log(err.request.response)
        })
    },
    _getReportTypes () {
      dictionaryService.list(new PageInfo({ pageSize: config.bigPageSize, pageIndex: 1, fieldOrder: 'sortId' }), { dictionaryKey: 'reportType' })
        .then(res => {
          if (res.data.code === 1) {
            this.reportTypes = res.data.data
          } else {
            this.$message({
              type: 'error',
              message: '获取数据失败！'
            })
          }
        })
        .catch(err => {
          this.$message({
            type: 'error',
            message: '获取数据失败！'
          })
          console.log(err.request.response)
        })
    },
    _getPlanStatTypes () {
      dictionaryService.list(new PageInfo({ pageSize: config.bigPageSize, pageIndex: 1, fieldOrder: 'sortId' }), { dictionaryKey: 'planStatType' })
        .then(res => {
          if (res.data.code === 1) {
            this.planStatTypes = res.data.data
          } else {
            this.$message({
              type: 'error',
              message: '获取数据失败！'
            })
          }
        })
        .catch(err => {
          this.$message({
            type: 'error',
            message: '获取数据失败！'
          })
          console.log(err.request.response)
        })
    }
  },
  created () {
    this._getReportTemplates()
    this._getReportTypes()
    this._getPlanStatTypes()
    this._getReportGroups()
  }
}
</script>
